import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService, TranslocoDirective } from '@ngneat/transloco';
import { Actions, Store } from '@ngxs/store';
import { Subject } from 'rxjs';

import { ConfigService } from '../../shared/services/config.service';
import { AuthState } from '../../shared/store/states/auth.state';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { SpaceDeleteInfoComponent } from '../space-delete-info/space-delete-info.component';
import { SpaceDelete } from '../../shared/store/actions/spaces.action';
import { ChatsGet } from '../../shared/store/actions/chats.action';
import { ToastrService } from 'ngx-toastr';
import { ProjectDelete } from '../../shared/store/actions/projects.action';

export interface SpaceData {
  space: {};
  project: {};
}
@Component({
  selector: 'app-space-delete',
  templateUrl: './space-delete.component.html',
  styleUrls: ['./space-delete.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, FormsModule, ReactiveFormsModule],
})
export class SpaceDeleteComponent implements OnInit, OnDestroy {
  destroy$: Subject<void> = new Subject<void>();
  SpaceData: SpaceData;

  spaceDeleteForm = new FormGroup({
    acceptedDeleteSpace: new FormControl(false, [Validators.required]),
    passwordUser: new FormControl('', [Validators.required]),
  });

  platform: string;
  space: any;
  project: any;

  config: any = {};

  constructor(
    private actions: Actions,
    private store: Store,
    private configService: ConfigService,
    private activeModal: NgbActiveModal,
    public modalService: NgbModal,
    protected toastrService: ToastrService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.config = this.configService.templateConf;
    this.initModalData(this.SpaceData);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close() {
    this.activeModal.close();
  }

  onSubmit() {
    if (this.space) {
      this.store
        .dispatch(
          new SpaceDelete({
            id: this.space._id,
          }),
        )
        .subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.space-successfully-deleted'),
              this.translocoService.translate('toastr.space-deleted-title'),
            );

            this.store.dispatch(new ChatsGet());

            this.close();
            const modalRef = this.modalService.open(SpaceDeleteInfoComponent, {
              size: 'md',
              centered: true,
            });
            modalRef.componentInstance.InfoData = {
              space: this.space,
            };
          },
          (err) => {
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            );
          },
        );
    } else {
      this.store
        .dispatch(
          new ProjectDelete({
            id: this.project._id,
            body: {
              password: this.spaceDeleteForm.controls.passwordUser.value,
            },
          }),
        )
        .subscribe(
          () => {
            this.toastrService.success(
              this.translocoService.translate('toastr.project-successfully-deleted'),
              this.translocoService.translate('toastr.project-deleted-title'),
            );

            this.store.dispatch(new ChatsGet());

            this.close();
            const modalRef = this.modalService.open(SpaceDeleteInfoComponent, {
              size: 'md',
              centered: true,
            });
            modalRef.componentInstance.InfoData = {
              space: this.space,
            };
          },
          (err) => {
            this.toastrService.error(
              err.message,
              this.translocoService.translate('toastr.title-error'),
            );
          },
        );
    }
  }

  initModalData(data: SpaceData): void {
    this.space = data.space;
    this.project = data.project;
    this.platform = this.store.selectSnapshot(AuthState.getPlatform);
  }
}
